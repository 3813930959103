import React from "react";
import "./App.css";

const CancellationProcessModal = ({ onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>

        <h2>Cancellation Process</h2>
        <p>If you cancel your subscription, you will continue to have access until the end of your current billing cycle.</p>
        
        <h3>Important Steps After Cancellation</h3>
        <p>To ensure the catering form is removed from your website, please delete the following embedded JavaScript code from your site:</p>
        
        <div className="code-block">
          <code>
            {`<div id="food-form-container"></div>
<script src="https://collaborative-catering-21ab4e9a8bfb.herokuapp.com/embed-script/YOUR_PUBLIC_ID"></script>`}
          </code>
        </div>

        <p>If you do not remove this code, the catering form will no longer display, but the script will remain on your site.</p>

        <button className="close-button-bottom" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default CancellationProcessModal;
