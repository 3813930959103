import React from "react";
import "./App.css";

const TermsOfServiceModal = ({ onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>

        <h2>Terms of Service</h2>
        <p className="date">Effective Date: February 02, 2025</p>

                <div className="terms-scroll">
          <h3>1. Introduction</h3>
          <p>
            Welcome to <strong>Collaborative Catering</strong>. These Terms of Service ("Terms") govern your use of our smart catering intake platform, including our website, embedded form services, and subscription-based offerings. By creating an account, accessing the platform, or subscribing to our services, you agree to these Terms.
          </p>
          <p>If you do not agree, you may not use the Service.</p>

          <h3>2. Account Registration & Security</h3>
          <p>- To use the Service, you must create an account through Auth0 authentication.</p>
          <p>- You must provide accurate and up-to-date information during registration.</p>
          <p>- You are responsible for maintaining the confidentiality of your login credentials.</p>
          <p>- Any unauthorized access to your account should be reported immediately.</p>

          <h3>3. Subscription & Billing</h3>
          <p>- The Service operates on a subscription basis, with payments processed via Paddle.</p>
          <p>- <strong>Automatic Renewal:</strong> Subscriptions renew automatically unless canceled.</p>
          <p>- <strong>Payment Responsibility:</strong> You are responsible for keeping payment details updated.</p>
          <p>- <strong>Failed Payments:</strong> If a payment fails, your access may be restricted until resolved.</p>

          <h3>4. Refund & Trial Policy</h3>
          <p>- We offer a 14-day free trial for new users.</p>
          <p>- You may cancel your subscription at any time during the trial period without being charged.</p>
          <p>- If your account has already been charged, we do not offer refunds.</p>
          <p>- Your subscription will remain active and usable until the end of the billing cycle for which you have paid.</p>

          <h3>5. Acceptable Use Policy</h3>
          <p>By using the Service, you agree not to:</p>
          <ul>
            <li>Use the Service for illegal, fraudulent, or harmful purposes.</li>
            <li>Embed our catering form on sites promoting hate speech, violence, or illegal activities.</li>
            <li>Attempt to reverse-engineer, copy, or resell our Service without permission.</li>
            <li>Disrupt or interfere with the integrity and performance of our platform.</li>
          </ul>
          <p>Violation of this policy may result in immediate termination of your account.</p>

          <h3>6. Account Termination & Cancellation</h3>
          <p>- You can cancel your subscription anytime through Paddle’s Customer Portal.</p>
          <p>- We reserve the right to suspend or terminate accounts that violate these Terms.</p>
          <p>- If your subscription is canceled or payment fails, access to premium features will be revoked.</p>

          <h3>7. Data Privacy & Security</h3>
          <p>- We collect only necessary data to operate our Service, such as your email, form configurations, and subscription status.</p>
          <p>- We do not sell or share your personal data with third parties except as required to process subscriptions or comply with legal obligations.</p>
          <p>- Your authentication is managed securely through Auth0, and payments are processed via Paddle—we do not store credit card details.</p>
          <p>- For full details on how we handle your data, contact <strong>info@collaborativecatering.com</strong>.</p>

          <h3>8. Email Communications & Opt-Out Policy</h3>
          <p>- By signing up, you agree to receive emails related to your account and subscription at the email associated with your Auth0 login.</p>
          <p>- To stop receiving emails, you must cancel your subscription via Paddle’s Customer Portal.</p>
          <p>- Marketing emails include an unsubscribe link in compliance with CAN-SPAM and GDPR regulations.</p>

          <h3>9. Service Availability & Liability</h3>
          <p>- We strive for 99.9% uptime, but we do not guarantee uninterrupted service.</p>
          <p>- We are not liable for indirect, incidental, or consequential damages resulting from service interruptions, loss of data, or unauthorized access.</p>
          <p>- Our total liability under these Terms shall not exceed the amount paid for your subscription in the last three (3) months.</p>

          <h3>10. Changes to Terms</h3>
          <p>- We may update these Terms from time to time.</p>
          <p>- Continued use of the Service after updates constitutes acceptance of the revised Terms.</p>

          <h3>11. Contact Information</h3>
          <p>For any questions about these Terms, email us at <strong>info@collaborativecatering.com</strong>.</p>
        </div>

        <button className="close-button-bottom" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default TermsOfServiceModal;
